<script>
import Layout from "@/layouts/main.vue"
import API from "@/plugins/axios-method";
import PageHeader from "@/components/page-header.vue";
import NotFound from "@/components/not-found.vue";
import DataLoader from "@/components/data-loader.vue";
import AccountOwnerInformation from "@/components/dashboard/account/account-owner-information.vue";
import AccountBasiqueInformations from "@/components/dashboard/account/account-basique-informations.vue";
import AccountSubscription from "@/components/dashboard/account/account-subscription.vue";
import AccountTransactions from "@/components/dashboard/account/account-transactions.vue";
import AccountFundingRequest from "@/components/dashboard/account/account-funding-request.vue";
import AccountFunding from "@/components/dashboard/account/account-funding.vue";
export default {
  name: "single-account",
  components: {
    AccountFunding,
    AccountFundingRequest,
    AccountTransactions,
    AccountSubscription,
    AccountBasiqueInformations, AccountOwnerInformation, DataLoader, NotFound, PageHeader, Layout},
  data(){
    return {
      account: null,
      showDataLoader: true,
      notFound: false,
    }
  },
  mounted() {
    this.getAccount()
  },
  methods: {
    getAccount(){
      API.get('admin/accounts/' + this.$route.params.id)
          .then(response => {
            this.account = response.data
          })
          .catch(e => {
            if(e.response.status === 404){
              this.notFound = true
            }
          })
          .finally(() => {
            this.showDataLoader = false
          })
    }
  },
  computed: {
    canShowData(){
      return !this.showDataLoader && this.account
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Compte numéro ' +this.$route.params.id"
        :show-search="false"
        :show-add-button="false"
    />
    <DataLoader v-if="showDataLoader"/>
    <NotFound v-if="!showDataLoader && notFound"/>
    <section v-if="canShowData">
      <b-row>
        <b-col md="6">
          <account-owner-information :account="account" />
        </b-col>
        <b-col md="6">
          <account-basique-informations :account="account" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <account-subscription :account="account" />
        </b-col>
        <b-col md="12">
          <account-transactions :transactions="account.successfullTransactions"/>
        </b-col>
        <b-col md="12">
          <account-funding-request :funding-requests="account.fundingRequests"/>
        </b-col>
        <b-col md="12">
          <account-funding :funding="account.fundings"/>
        </b-col>
      </b-row>
    </section>
  </Layout>
</template>

<style scoped>

</style>