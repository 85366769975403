<script>

import ClientPagination from "@/components/client-pagination.vue";

export default {
  name: "account-funding",
  components: {ClientPagination},
  props: {
    funding: {
      type: Array,
      required: true
    }
  },
  data(){
    return {
      currentPage: 1,
      itemsPerPage: 5,
    }
  },
  methods: {
    nextPage(){
      if (this.currentPage < (this.funding.length / this.itemsPerPage)) {
        this.currentPage++;
      }
    },
    previousPage(){
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.funding.slice(start, end);
    },
  }
}
</script>

<template>
  <b-card>
    <b-card-header>
      <b-card-title>Financements</b-card-title>
    </b-card-header>
    <b-card-body>
      <div class="table-responsive">
        <table class="table table-hover table-striped align-middle table-nowrap mb-0">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Montant demandé</th>
            <th scope="col">Intéret (%)</th>
            <th scope="col">Montant intéret</th>
            <th scope="col">Montant à rembourser</th>
            <th scope="col">Statut</th>
            <th scope="col">Montant remboursé</th>
            <th scope="col">Date</th>
            <th scope="col">Echéance</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(f, index) in paginatedData" :key="index">
            <th scope="row">{{index + 1}}</th>
            <td>{{ f.amount?.toLocaleString() }}</td>
            <td>{{ f.rate }}</td>
            <td>{{ f.interestAmount?.toLocaleString() }}</td>
            <td>{{ f.refundAmount?.toLocaleString() }}</td>
            <td>
              <BBadge v-if="f.status === 0 || !f.status" variant="dark">Créée</BBadge>
              <BBadge v-else-if="f.status === 1" variant="warning">En cours de remboursement</BBadge>
              <BBadge v-else-if="f.status === 2" variant="success">Remboursé</BBadge>
            </td>
            <td>{{ f?.refund.refundedAmount.toLocaleString() }}</td>
            <td>{{ f.startAt.split('T')[0] }}</td>
            <td>{{ f.deathLine }}</td>
          </tr>
          </tbody>
        </table>
        <client-pagination
            :current-page="currentPage"
            :total-pages="funding.length / this.itemsPerPage"
            @nextPage="nextPage"
            @previousPage="previousPage"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>