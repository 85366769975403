<script>
export default {
  name: "client-pagination",
  props: {
    currentPage: {
      type: Number,
      default: 1,
      required: true
    },
    totalPages: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data() {

  },
  methods: {
    nextPage() {
      this.$emit('nextPage')
    },
    prevPage() {
      this.$emit('previousPage')
    },
  },
}
</script>

<template>
  <div class="mt-4">
    <nav aria-label="Page navigation mt-5">
      <ul class="pagination justify-content-end">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button class="page-link" @click="prevPage" :disabled="currentPage === 1">
            ←
          </button>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <button class="page-link" @click="nextPage" :disabled="currentPage === totalPages">
            →
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style scoped>

</style>