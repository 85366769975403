<script>

import ClientPagination from "@/components/client-pagination.vue";
import {getCreatedAndUpdatedDates} from "../../../helpers/functions";

export default {
  name: "account-funding-request",
  components: {ClientPagination},
  props: {
    fundingRequests: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      currentPage: 1,
      itemsPerPage: 5,
    }
  },
  methods: {
    getCreatedAndUpdatedDates,
    nextPage(){
      if (this.currentPage < (this.fundingRequests.length / this.itemsPerPage)) {
        this.currentPage++;
      }
    },
    previousPage(){
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.fundingRequests.slice(start, end);
    },
  }
}
</script>

<template>
  <b-card>
    <b-card-header>
      <b-card-title>Demande de financement</b-card-title>
    </b-card-header>
    <b-card-body>
      <div class="table-responsive">
        <table class="table table-hover table-striped align-middle table-nowrap mb-0">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Montant</th>
            <th scope="col">Statut</th>
            <th scope="col">Date</th>
            <th scope="col">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(fr, index) in paginatedData" :key="index">
            <th scope="row">{{index + 1}}</th>
            <td>{{ fr.amount?.toLocaleString() }}</td>
            <td>
              <BBadge v-if="fr.status === 0" variant="secondary">Créée</BBadge>
              <BBadge v-else-if="fr.status === 1" variant="warning">En Attente</BBadge>
              <BBadge v-else-if="fr.status === 2" variant="success">Acceptée</BBadge>
              <BBadge v-else-if="fr.status === 3" variant="danger">Refusée</BBadge>
            </td>
            <td>{{ getCreatedAndUpdatedDates(fr.createdAt) }}</td>
            <td>
              <div class="hstack gap-3 fs-15">
                <router-link :to="'/admin/funding-requests/' + fr.uniqId" class="link-primary">
                  <i class="ri-eye-fill"></i>
                </router-link>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <client-pagination
            :current-page="currentPage"
            :total-pages="fundingRequests.length / this.itemsPerPage"
            @nextPage="nextPage"
            @previousPage="previousPage"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>