<script>
export default {
  name: "account-subscription",
  props: {
    account: {
      type: Object,
      required: true,
    }
  }
}
</script>

<template>
  <b-card>
    <b-card-header>
      <b-card-title>Souscriptions</b-card-title>
    </b-card-header>
    <b-card-body>
     <div class="table-responsive">
       <table class="table table-striped">
         <thead>
         <tr>
           <th scope="col">#</th>
           <th scope="col">Montant</th>
           <th scope="col">Produit</th>
           <th scope="col">Programme Contribution</th>
           <th scope="col">Montant Contribution</th>
           <th scope="col">Date</th>
         </tr>
         </thead>
         <tbody>
         <tr v-for="(s, index) in account.activeSubscriptions" :key="index">
           <th scope="row">{{ index + 1 }}</th>
           <td>{{ s.amount.toLocaleString() }}</td>
           <td>{{ s.product.name }}</td>
           <td>{{ s.contributionProgram.amount.toLocaleString() }}</td>
           <td>{{ s.contribution?.currentAmount.toLocaleString() }}</td>
           <td>{{ s.createdAt ? s.createdAt.split('T')[0] : null }}</td>
         </tr>
         </tbody>
       </table>
     </div>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>